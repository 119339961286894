nav.social {
  position: absolute;
  top: 24px;
  right: 0;
  height: 24px;
  z-index: 101;

  @include respond-to( mobile ) {
    position: relative;
    top: 0;
    float: right;
    margin-top: 15px;
    height: 18px;
  }
  
  dl {
    height: 100%;
  }

  dt {
    display: none;
  }  
    
  dd {
    float: left;
    height: 100%;
    list-style-type: none;
    font-size: 12px;
    line-height: 24px;
    
    & + dd {
      margin-left: 9px;
    }

    &.network {
    }

    a {
      height: 100%;
      
      &[href*='linkedin.com'] {
        background-image: image( 'linkedin.svg' );

        @supports (mask-image: none) {
          background-image: none;
          mask-image: image( 'linkedin.svg' );
        }
      }
      
      &[href*='github.com'] {
        background-image: image( 'octocat.svg' );

        @supports (mask-image: none) {
          background-image: none;
          mask-image: image( 'octocat.svg' );
        }
      }
      
      &[href*='instagram.com'] {
        background-image: image( 'instagram.svg' );

        @supports (mask-image: none) {
          background-image: none;
          mask-image: image( 'instagram.svg' );
        }
      }
    }

    &.document {
      font-weight: bold;
      
      .details {
        font-size: 10px;
      }

      a + .details {
        margin-left: 3px;
      }
      
      & + dd.network {
        margin-left: 18px;
      }
    }
    
    &.network {
      width: 24px;
      
      a {
        display: block;
        background-color: transparent;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;

        @supports (mask-size: contain) {
          background: none;
          background-color: var( --color-black );
          mask-position: 50% 50%;
          mask-repeat: no-repeat;
          mask-size: contain;
          transition: background-color 250ms ease;

          &:hover {
            background-color: var( --color-link-hover );
          }
        }

        @include text-replace;
      }
    }
      
    @include respond-to( mobile ) {
      &.document {
        display: none;

        & + .network {
          margin-left: 0;
        }
      }

      &.network {
        width: 18px;

        & + .network {
          margin-left: 12px;
        }
      }
    }
  }
}
