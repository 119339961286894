main {
  padding-bottom: 5 * 18px;

  @include respond-to( widescreen ) {
    margin-left: auto;
    margin-right: auto;
    width: 1110px;
  }

  @include respond-to( mobile ) {
    padding-bottom: 6 * 18px;
  }
}
