nav.global {
  position: relative;
  clear: both;
  float: left;
  margin-top: 36px;
  z-index: 101;

  @include respond-to( mobile ) {
    margin-top: 12px;
    height: 27px;
  }

  ul {
    margin-left: -12px;

    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    
    &:after {
      clear: both;
    }

    li {
      clear: both;
      float: left;
      padding: 3px 12px;
      font-size: 15px;
      line-height: 24px;

      &:hover {
        a {
          color: var( --color-link-hover );
        }
      }

      a {
        border-bottom: 2px solid var( --color-link );
        color: inherit;
        font-weight: bold;
      }

      &.selected {
        background-color: rgba( var( --color-link-hover-rgb ), 0.5 );
        color: var( --color-white );

        a {
          color: var( --color-white );
          border-bottom-color: var( --color-white );
        }
      }

      .nav-description {
        &:after {
          content: ' / ';
        }
      }
      
      @include respond-to( mobile ) {
        clear: none;
        margin-top: -3px;
        font-size: 14px;

        & + li {
          margin-left: 3px;
        }

        .nav-description {
          display: none;
        }
      }
    }
  }
}
