//  image()

@function image($filename) {
  @return url( $image-directory + $filename );
}

// image-set

@mixin image-set($filename) {
  background-image: image($filename);
  background-image: -webkit-image-set( image( $filename ) 1x, image(str-insert( $filename, '@2x', -5) ) 2x );
}

// text-replace()

@mixin text-replace() {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
}

// respond-to()

@mixin respond-to($breakpoint) {
  $maxW: null !default;
  $minW: null !default;
  $maxH: null !default;
  $minH: null !default;
  
  $query: null !default;
  $queryW: null !default;
  $queryH: null !default;
  
  @if $breakpoint == liquidize-frame {
    $maxW: 1129px;
    $minW: 480px;
  }
  @if $breakpoint == widescreen {
    $minW: 1110px;
  }
  @else if $breakpoint == liquidize-guts {
    $maxW: 1129px;
    $minW: 930px;
  }
  @else if $breakpoint == min-projects {
    $maxW: 1000px;
    $minW: 480px;
  }
  @else if $breakpoint == column-text {
    $maxW: 930px;
    $minW: 681px;
  }
  @else if $breakpoint == fullwidth-projects {
    $maxW: 800px;
    $minW: 681px;
  }
  @else if $breakpoint == mobile {
    $maxW: 680px;
    $minW: null;
  }
  
  @if $breakpoint != modernize {
    @if $minW and $maxW {
      $queryW: "(max-width: #{$maxW}) and (min-width: #{$minW})";
    }
    @if $minW == null and $maxW {
      $queryW: "(max-width: #{$maxW})";
    }
    @else if $minW and $maxW == null {
      $queryW: "(min-width: #{$minW})";
    }

    @if $minH and $maxH {
      $queryH: "(max-height: #{$maxH}) and (min-height: #{$minH})";
    }
    @if $minH == null and $maxH {
      $queryH: "(max-height: #{$maxH})";
    }
    @else if $minH and $maxH == null {
      $queryH: "(min-height: #{$minH})";
    }
  }
  
  @if $queryW and $queryH {
    $query: "#{$queryW} and #{$queryH}";
  }
  @else if $queryW and $queryH == null {
    $query: $queryW;
  }
  @else if $queryW == null and $queryH {
    $query: $queryH;
  }

  @if $query == null {
    @media only screen { @content; }
  }
  @else {
    @media only screen and #{$query} { @content; }
  }

}