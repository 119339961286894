.shapes-area {
  position: absolute;
  top: -36px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 40%;
  height: calc(100% + 36px);
  opacity: 1;
  overflow: hidden;
  scale: 1;
  translate: 0 0;
  visibility: visible;
  z-index: 100;
  transition: opacity 250ms ease,
              scale 250ms ease,
              translate 125ms ease,
              visibility 250ms ease;

  body.interior & {
    opacity: 0;
    scale: 0.5;
    translate: 0 -25vh;
    visibility: hidden;
    transition: opacity 250ms ease,
                scale 250ms ease,
                translate 500ms ease,
                visibility 250ms ease;
  }

  @include respond-to( mobile ) {
    padding-top: 192px;
    left: -54px;
    padding-left: 0;
  }
}
