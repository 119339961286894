:root {
  --color-black-rgb:      51, 51, 50;
  --color-black:          rgb( var( --color-black-rgb ) );
  --color-white-rgb:      255, 255, 255;
  --color-white:          rgb( var( --color-white-rgb ) );
  --color-gray-dark:      rgb( 33, 33, 33 );
  --color-gray-medium:    #999;
  --color-gray-light:     rgb( 232, 231, 227 );

  --color-default:        var( --color-gray-dark );
  --color-sky:            rgb( 181, 214, 235 );

  --color-blue-rbg:       88, 142, 187;
  --color-blue:           rgb( var( --color-blue-rbg ) );

  --color-link:           var( --color-default );
  --color-link-hover-rgb: var( --color-blue-rgb );
  --color-link-hover:     var( --color-blue );
}
