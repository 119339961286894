html {
  height: 100%;
  font-size: $root-font-size + px;
}

body {
  height: 100%;
  background-attachment: fixed;
  background-color: var( --color-white );
  background-image: linear-gradient(to bottom, var( --color-sky ) 0%, var( --color-white) 50%, var( --color-white) 100%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var( --color-default );
  font-family: $font-stack;
  font-size: 100%;
  transition: background-position 250ms ease;

  @include respond-to( mobile ) {
    min-height: 380px;
    background-size: 100% 100%;
    background-position: 50% 100%;
  }

  &.interior {
    background-position: 0 -5vw;
  }
}

a {
  color: var( --color-link );
  text-decoration: none;
  transition: color 250ms ease;
  
  &:active {
    text-decoration: underline;
  }
  
  &:hover {
    color: var( --color-link-hover );
    text-decoration: none;
  }
}

#screen {
  position: relative;
  min-height: 100%;
  background-attachment: scroll;
  background-color: transparent;
  background-image: url('/images/hero/tabor-color.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 67%;

  @include respond-to( mobile ) {
    background-size: 200%;
    background-position: 50% 100%;
  }
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
