/*  HEADER
\_____________________________________________________________*/

header.global {
  position: relative;
  z-index: 101;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 24px;

  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  
  &:after {
    clear: both;
  }
  
  h1 {
    float: left;
    position: relative;
    font-size: 18px;
    line-height: 24px;
    z-index: 1003;
    
    a {
      font-weight: bold;
    }

    @include respond-to( mobile ) {
      float: none;
      margin-bottom: 18px;
      height: auto;
    }
  }

  @include respond-to( widescreen ) {
    margin-left: auto;
    margin-right: auto;
    width: 1110px - (24px * 2);
  }
}
