/* 

Shapes via 
http://css-tricks.com/examples/ShapesOfCSS/

*/

$default-measure: 48px;

$themes: (
  default: (
    blank:    rgba(0, 0, 0, 0),
    lighter:  rgba(144, 238, 144, 0.5),
    light:    rgba(128, 128, 128, 0.5),
    medium:   rgba(88, 142, 187, 0.5),
    dark:     rgba(88, 142, 187, 0.75),
    darker:   rgba(0, 0, 0, 0.25)
  ),
  neue: (
    blank:    rgba(0, 0, 0, 0),
    lighter:  rgba(144, 215, 160, 0.65),
    light:    rgba(102, 102, 102, 0.75),
    medium:   rgba(88, 142, 187, 0.5),
    dark:     rgba(88, 142, 187, 0.75),
    darker:   rgba(0, 0, 0, 0.25)
  ),
  spring: (
    blank:    rgba(0, 0, 0, 0),
    lighter:  rgba(134, 168, 81, 0.5),
    light:    rgba(221, 214, 194, 0.75),
    medium:   rgba(161, 206, 206, 0.5),
    dark:     rgba(109, 157, 183, 0.75),
    darker:   rgba(234, 230, 219, 0.75)
  )
);

$theme: spring;

.shapes {
  display: flex;
  min-width: $default-measure * 15;
  height: $default-measure;
}

.triangle,
.trapezoid,
.square,
.circle {
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @each $grade, $hue in map-get($themes, $theme) {
    &.#{$grade} {
      svg {
        fill: $hue;
      }
    }
  }
}

.trapezoid {
  width: $default-measure * 3;

  &.trapezoid-n {
    margin-left: -$default-measure;

    & + .trapezoid-s {
      margin-left: -$default-measure;
    }
  }
}

.square,
.circle,
.triangle {
  width: $default-measure;
  height: $default-measure;
}

.triangle {
  &.triangle-ne,
  &.triangle-se {
    margin-left: -$default-measure;
  }

  &.triangle-nw + .trapezoid-s {
    margin-left: -$default-measure;
  }
}
